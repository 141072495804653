<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="certificateImplementSaveModal"
        body-class="position-static"
        centered
        :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ lệu'"
        no-close-on-backdrop
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-form-group>
          <template v-slot:label>
            Tên khung thời gian triển khai <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors}"
              name="Tên khung thời gian triển khai"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.name"
                placeholder="Tên khung thời gian triển khai"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <template v-slot:label>
            Mô tả khung thời gian triển khai <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors}"
              name="Tên khung thời gian triển khai"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.description"
                placeholder="Mô tả khung thời gian triển khai"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="startYear">
          <template v-slot:label>
            Năm học bắt đầu triển khai <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Năm học bắt đầu"
              rules="required"
          >
            <v-select
                v-model="targetItem.startYearId"
                :options="schoolYears"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="endYear">
          <template v-slot:label>
            Năm học kết thúc triển khai <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Năm học kết thúc"
              rules="required"
          >
            <v-select
                v-model="targetItem.endYearId"
                :options="schoolYears"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group
            label="Thời gian bắt đầu triển khai/1 đợt"
            label-for="time_implement"
        >
          <b-form-datepicker
              id="datepicker-dateformat2"
              v-model="targetItem.timeImplement"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="vi"
              format="dd/mm/yyyy"
              placeholder="dd/mm/yyyy"
          />
        </b-form-group>

        <b-form-group
            label="Thời gian kết thúc triển khai/1 đợt"
            label-for="time_end"
        >
          <b-form-datepicker
              id="datepicker-dateformat3"
              v-model="targetItem.timeEnd"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="vi"
              format="dd/mm/yyyy"
              placeholder="dd/mm/yyyy"
          />
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
          >
            <v-select
                v-model="targetItem.status"
                :options="statusOptions"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('certificateImplementSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BButton, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CertificateImplementSave',
  components: {
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      idLoading: false,
      targetItem: {
        name: '',
        description: '',
        startYearId: 0,
        endYearId: 0,
        timeImplement: '',
        timeEnd: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'certificateImplement/statuses',
      schoolYears: 'dropdown/schoolYears',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.item
    },
  },
  async created() {
  },
  methods: {
    ...mapActions({
      getSchoolYears: 'dropdown/getSchoolYears',
      createCertificateImplement: 'certificateImplement/createCertificateImplement',
      updateCertificateImplement: 'certificateImplement/updateCertificateImplement',
    }),
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
      await this.getSchoolYears()
    },
    async onSave(type = null) {
      const valid = this.$refs
          .saveFormRef
          .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
              ? await this.createCertificateImplement(this.targetItem)
              : await this.updateCertificateImplement(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('certificateImplementSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    onHide() {
      this.targetItem = {
        name: '',
        description: '',
        startYearId: 0,
        endYearId: 0,
        timeImplement: '',
        timeEnd: '',
        status: 1,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
<style scoped lang="scss">

</style>
